import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { VENDOR_BRANCH_API } from 'configs/api';
import { useHttpClient, useVendor } from 'hooks';
import { throttle } from 'utils/throttle';
import { IBranch } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';

interface IBranchContextData {
	branchId: number;

	data: Nullable<IBranch>;
	error: Nullable<any>;
	isLoading: boolean;

	// ! handlers
	fetchData: TEmptyFunction;
}

const BranchContext = createContext<IBranchContextData>({} as IBranchContextData);

const useBranch = () => {
	return useContext(BranchContext);
};

interface IBranchProviderProps extends PropsWithChildren {
	branchId?: number;
}

const BranchProvider = ({ children, branchId: branchIdArg }: IBranchProviderProps) => {
	const { vendorId } = useVendor();
	const { branchId: branchIdParam } = useParams();

	// ! http client
	const fetchHttpClient = useHttpClient<IBranch>();

	// ! memos
	const branchId = useMemo<number | undefined>(() => {
		const parsedBranchIdParam = branchIdParam ? parseInt(branchIdParam) : undefined;

		return branchIdArg ?? parsedBranchIdParam;
	}, [branchIdArg, branchIdParam]);

	// ! handlers
	const fetchData = throttle(() => {
		if (!vendorId || !branchId || fetchHttpClient.isLoading) return;

		fetchHttpClient.request({
			requestConfig: VENDOR_BRANCH_API.get(vendorId, branchId),
		});
	});

	// ! useEffects
	useEffect(() => {
		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchId]);

	// ! render
	if (!branchId) return null;

	const branchData: IBranchContextData = {
		branchId: +branchId,
		data: fetchHttpClient.response,
		error: fetchHttpClient.error,
		isLoading: fetchHttpClient.isLoading,

		// * handlers
		fetchData,
	};

	return <BranchContext.Provider value={branchData}>{children}</BranchContext.Provider>;
};

export { useBranch, BranchProvider };
