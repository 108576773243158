import { EChoiceOption } from 'types/common';
import { IBEIntegrationSetting, IFEIntegrationSetting } from './types';
import { TIntegrationTabPageType } from 'pages/VendorManagement/IntegrationTabWrapper/types';
import { EVendorTabsKeys } from 'pages/VendorManagement/types';

export const transformBEtoFE = (type: string, settings: IBEIntegrationSetting): IFEIntegrationSetting => {
	const tabsAccessFromBeFieldToTIntegrationTabPageType: Record<TIntegrationTabPageType, keyof IBEIntegrationSetting> =
		{
			[EVendorTabsKeys.CATEGORIES]: 'categories_access',
			[EVendorTabsKeys.MENUS]: 'menus_access',
			[EVendorTabsKeys.PRODUCTS]: 'products_access',
			[EVendorTabsKeys.WORKING_SHIFTS]: 'working_shifts_access',
		};

	const tabsAccess = Object.entries(tabsAccessFromBeFieldToTIntegrationTabPageType).reduce<
		Record<TIntegrationTabPageType, boolean>
	>(
		(acc, [tabFeKey, beKey]) => {
			const settingsAccessValue: boolean = settings[beKey] === EChoiceOption.YES;

			acc[tabFeKey as TIntegrationTabPageType] = settingsAccessValue;

			return acc;
		},
		{
			[EVendorTabsKeys.CATEGORIES]: true,
			[EVendorTabsKeys.MENUS]: true,
			[EVendorTabsKeys.PRODUCTS]: true,
			[EVendorTabsKeys.WORKING_SHIFTS]: true,
		}
	);

	const newSetting: IFEIntegrationSetting = {
		name: settings.name,
		isIntegration: type !== 'platform',
		status_toggle_feature: settings.branch_status_toggle_feature === EChoiceOption.YES,
		color: settings.color,
		tabs_access: tabsAccess,
		change_workshift_source_feature: settings.change_workshift_source_feature === EChoiceOption.YES,
		menu_product_snooze_feature: settings.menu_product_snooze_feature === EChoiceOption.YES,
		manual_menu_sync: settings.manual_menu_sync === EChoiceOption.YES,
	};

	return newSetting;
};
