import { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { useCountry } from 'hooks';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';

export const ProtectedAccessByCountryWrapper: FC<{
	children?: ReactNode;
	country_white_list: ESupportedCountriesIsoTwoCodes[];
}> = ({ country_white_list, children = <Outlet /> }) => {
	const { selectedCountryIsoTwoCode } = useCountry();

	if (!selectedCountryIsoTwoCode) {
		return null;
	}

	if (!country_white_list.includes(selectedCountryIsoTwoCode as ESupportedCountriesIsoTwoCodes)) {
		return (
			<Navigate
				to={ABSOLUTE_ROUTES[404]}
				replace
			/>
		);
	}

	return <>{children}</>;
};
