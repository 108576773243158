import { useEffect, useRef, useState } from 'react';
import { VENDOR_BRANCH_API } from 'configs/api';
import { useBranch, useHttpClient, useVendor } from 'hooks';
import { IArea } from 'types/api';
import { IListResponse, Nullable } from 'types/common';

export const useBranchDeliveryAreaListController = () => {
	const { vendorId } = useVendor();
	const { branchId } = useBranch();
	const [selectedAreas, setSelectedAreas] = useState<Record<IArea['id'], boolean>>({});

	const areaMap = useRef<Record<IArea['id'], IArea>>({});

	const [areaIdInfoWindow, setAreaIdInfoWindow] = useState<Nullable<IArea['id']>>(null);
	const [hoveredAreaId, setHoveredAreaId] = useState<Nullable<IArea['id']>>(null);

	// ! httpClients
	const getHttpClient = useHttpClient<IListResponse<IArea>>();

	const fetchPage = () => {
		return getHttpClient.request({
			requestConfig: VENDOR_BRANCH_API.getDeliveryAreas(vendorId, branchId),
			successCallback: ({ data }) => {
				const selectedAreasMap: Record<IArea['id'], boolean> = {};

				data.forEach((area) => {
					areaMap.current[area.id] = area;
					selectedAreasMap[area.id] = true;
				});

				setSelectedAreas(selectedAreasMap);
			},
		});
	};

	// ! handlers
	const selectArea = (id: IArea['id']) => {
		setSelectedAreas((prev) => {
			const newState = { ...prev };

			if (newState[id] === undefined) {
				newState[id] = false;
			}

			newState[id] = !newState[id];
			return newState;
		});
	};

	// ! effects
	useEffect(() => {
		fetchPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! return
	return {
		areas: Object.values(areaMap.current),
		areaMap,
		selectedAreas,
		areaIdInfoWindow,
		hoveredAreaId,
		setAreaIdInfoWindow,
		setHoveredAreaId,
		setSelectedAreas,
		selectArea,
	};
};
