import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { GENERAL_SETTINGS_API } from 'configs/api';
import { useHttpClient } from 'hooks';
import { transformBEtoFE } from './helpers';
import { TIntegrationType } from 'types/api';
import { Nullable } from 'types/common';
import { IBEIntegrationSetting, IFEIntegrationSetting } from './types';

interface IIntegrationsContextData {
	isLoading: boolean;
	data: Record<string, IFEIntegrationSetting> | undefined;
	getIntegrationTypeSettings: (integrationType: TIntegrationType) => Nullable<IFEIntegrationSetting>;
}

export const IntegrationsContext = createContext<IIntegrationsContextData>({} as IIntegrationsContextData);

export const IntegrationsProvider: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const [data, setData] = useState<Record<TIntegrationType, IFEIntegrationSetting>>();

	// ! http clients
	const fetchHttpClient = useHttpClient<Record<TIntegrationType, IBEIntegrationSetting>>();

	// ! handlers
	const fetchIntegrations = () => {
		fetchHttpClient.request({
			requestConfig: GENERAL_SETTINGS_API.integrationSettings(),
			successCallback: (response) => {
				const integrationRecordByType = Object.entries(response).reduce<
					Record<TIntegrationType, IFEIntegrationSetting>
				>((acc, [key, value]) => {
					acc[key] = transformBEtoFE(key, value);

					return acc;
				}, {});

				setData(integrationRecordByType);
			},
		});
	};

	const getIntegrationTypeSettings = (integrationType: TIntegrationType) => {
		if (!data) return null;

		return data[integrationType];
	};

	// ! effects
	useEffect(() => {
		if (!data) {
			fetchIntegrations();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const contextData: IIntegrationsContextData = {
		data,
		isLoading: fetchHttpClient.isLoading,
		getIntegrationTypeSettings,
	};

	return <IntegrationsContext.Provider value={contextData}>{children}</IntegrationsContext.Provider>;
};
