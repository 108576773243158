import { GLOBAL_MAP_CONTAINER_STYLE } from 'configs/common';

export const POLYGON_MAX_EDGE_POINTS = 100;

export const OPERATIONS_MAP_CONFIG = {
	...GLOBAL_MAP_CONTAINER_STYLE,
	height: '600px',
};

export const DEFAULT_POLYGON_OPTIONS = {
	fillOpacity: 0.2,
	strokeOpacity: 1,
	strokeWeight: 2,
	zIndex: 1,
};
