import { FC, useMemo } from 'react';
import { IArea } from 'types/api';
import { Polygon, TPolygonProps } from './Polygon';

interface IAreaPolygonProps extends TPolygonProps {
	area: IArea;
	clickable: boolean;
	isHovered: boolean;
	isIntersected?: boolean;
	isSelected: boolean;
	isVisible: boolean;
	onClick: () => void;
	onMouseOver: () => void;
	onRightClick?: () => void;
}

export const AreaPolygon: FC<IAreaPolygonProps> = ({
	area,
	isHovered,
	isIntersected,
	isSelected,
	isVisible,
	onClick,
	onMouseOver,
	onRightClick,
	...restProps
}) => {
	// ! memos
	const colorOptions = useMemo(() => {
		let fillColor = 'lightsteelblue';

		let strokeColor = fillColor;
		let zIndex = 1;

		if (isHovered) {
			fillColor = 'grey';
			strokeColor = 'grey';

			zIndex = 2;
		}

		if (isSelected) {
			fillColor = 'green';
			strokeColor = 'green';
		}

		if (isIntersected) {
			fillColor = 'orange';
			strokeColor = 'orange';
		}

		return { fillColor, strokeColor, zIndex };
	}, [isHovered, isIntersected, isSelected]);

	// ! render
	return (
		<Polygon
			key={`Polygon-${area.id}`}
			visible={isVisible}
			path={area.polygon?.coordinates ?? []}
			onClick={onClick}
			onMouseOver={onMouseOver}
			{...colorOptions}
			{...restProps}
		/>
	);
};
