export const polygonsIntersect = (path1: google.maps.LatLngLiteral[] = [], path2: google.maps.LatLngLiteral[] = []) => {
	if (path2.length === 0 || path1.length === 0) {
		return false;
	}

	const polygon1 = new google.maps.Polygon();
	polygon1.setPath(path1);
	const polygon2 = new google.maps.Polygon();
	polygon2.setPath(path2);

	// Check if any vertex of polygon1 is inside polygon2
	for (let i = 0; i < path1.length; i++) {
		if (google.maps.geometry.poly.containsLocation(path1[i], polygon2)) {
			return true;
		}
	}

	// Check if any vertex of polygon2 is inside polygon1
	for (let i = 0; i < path2.length; i++) {
		if (google.maps.geometry.poly.containsLocation(path2[i], polygon1)) {
			return true;
		}
	}

	// Check if any edges of polygon1 and polygon2 intersect
	for (let i = 0; i < path1.length; i++) {
		const nextIndex = (i + 1) % path1.length;
		const segment1Start = path1[i];
		const segment1End = path1[nextIndex];

		for (let j = 0; j < path2.length; j++) {
			const nextIndex2 = (j + 1) % path2.length;
			const segment2Start = path2[j];
			const segment2End = path2[nextIndex2];

			if (doLineIntersect(segment1Start, segment1End, segment2Start, segment2End)) {
				return true;
			}
		}
	}

	// If no intersection found
	return false;
};

// Line Intersection Start
// ? https://bryceboe.com/2006/10/23/line-segment-intersection-algorithm/
const ccw = (a: google.maps.LatLngLiteral, b: google.maps.LatLngLiteral, c: google.maps.LatLngLiteral) =>
	(c.lat - a.lat) * (b.lng - a.lng) > (b.lat - a.lat) * (c.lng - a.lng);

export const doLineIntersect = (
	a: google.maps.LatLngLiteral,
	b: google.maps.LatLngLiteral,
	c: google.maps.LatLngLiteral,
	d: google.maps.LatLngLiteral
) => {
	return ccw(a, c, d) !== ccw(b, c, d) && ccw(a, b, c) !== ccw(a, b, d);
};
// Line Intersection End

export const getCenter = (arr: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral => {
	if (arr.length === 0) {
		throw new Error('Array must contain at least one LatLngLiteral.');
	}

	let minX = arr[0].lat;
	let maxX = arr[0].lat;
	let minY = arr[0].lng;
	let maxY = arr[0].lng;

	for (let i = 0; i < arr.length; i++) {
		const lat = arr[i].lat;
		const lng = arr[i].lng;

		minX = lat < minX || minX === null ? lat : minX;
		maxX = lat > maxX || maxX === null ? lat : maxX;
		minY = lng < minY || minY === null ? lng : minY;
		maxY = lng > maxY || maxY === null ? lng : maxY;
	}

	return {
		lat: (minX! + maxX!) / 2,
		lng: (minY! + maxY!) / 2,
	};
};
