import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PROMOTIONS_API } from 'configs/api/promotions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { CAMPAIGN_ACTIONS } from './configs';
import { PROMOTION_CAMPAIGN_LIST_TABLE } from './configs.campaigns';
import { THttpRequestConfig, useAuth, useCustomSearchParams, useForceRefreshData, useHttpClient } from 'hooks';
import { generatePromotionListTableColumns } from './helpers';
import { EPromotionType, IPromotionListResponse } from 'types/api';
import { Nullable } from 'types/common';
import { EPromotionListActions, IPromotionTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import TableWrapper, { ColumnFiltersSelect, TKeyBooleanRecord } from 'components/Table';
import { RecommendedSection } from '../RecommendedSection/RecommendedSection';
import ViewCampaignDrawer from '../SharedComponents/ViewCampaignDrawer';
import { App, Button, Space } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import { EyeOutlined, PauseCircleOutlined, PlayCircleOutlined, RocketOutlined, StopOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import type { FixedType } from 'rc-table/lib/interface';

const CampaignsList: FC = () => {
	const { t: tCommon } = useTranslation('common');
	const { t: tCampaigns } = useTranslation('campaigns');
	const { vendorId } = useParams();

	const [searchParams] = useCustomSearchParams();
	const { forceRefreshData, refreshingData } = useForceRefreshData();
	const navigator = useNavigate();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedCampaign, setSelectedCampaign] = useState<IPromotionTableData>();
	const { user } = useAuth();

	const selectedVendor = user?.vendors.find((vendor) => vendor.id === +vendorId!);

	const { message } = App.useApp();

	// ! http clients
	const updateHttpClient = useHttpClient();

	const defaultTableColumns = useMemo(
		() => generatePromotionListTableColumns(PROMOTION_CAMPAIGN_LIST_TABLE, searchParams),
		[searchParams]
	);

	const [selectedColumns, setSelectedColumns] = useState<TKeyBooleanRecord>(
		defaultTableColumns.reduce<TKeyBooleanRecord>((acc, column) => {
			if (column.key) {
				acc[column.key] = true;
			}
			return acc;
		}, {})
	);

	// ! memos
	const requestConfig = useMemo(() => {
		return PROMOTIONS_API.list(+vendorId!, EPromotionType.CAMPAIGN);
	}, [vendorId]);

	// ! handlers
	const handleCampaignPreviewOpen = (campaign: IPromotionTableData) => {
		setOpenDrawer(true);
		setSelectedCampaign(campaign);
	};
	const handleCampaignPreviewClose = () => {
		setOpenDrawer(false);
	};
	const handleChangeCampaignStatus = (operationType: EPromotionListActions, promotion: IPromotionTableData) => {
		const configs: Record<EPromotionListActions, Nullable<THttpRequestConfig>> = {
			[EPromotionListActions.RESUME]: PROMOTIONS_API.resume(+vendorId!, promotion.id),
			[EPromotionListActions.PAUSE]: PROMOTIONS_API.pause(+vendorId!, promotion.id),
			[EPromotionListActions.STOP]: PROMOTIONS_API.stop(+vendorId!, promotion.id),
			[EPromotionListActions.LAUNCH]: PROMOTIONS_API.launch(+vendorId!, promotion.id),
			[EPromotionListActions.VIEW]: null,
			[EPromotionListActions.PUSH]: null,
		};

		const actionHttpConfig = configs[operationType];

		if (!actionHttpConfig) {
			return;
		}

		updateHttpClient.request({
			requestConfig: actionHttpConfig,
			successCallback: () => {
				message.success(
					tCampaigns(`actions.${operationType}.success`, {
						promotion_name: promotion.info.title,
					}),
					5
				);

				refreshingData();
			},
		});
	};

	const onConsult = (campaign: IPromotionTableData) => {
		handleCampaignPreviewOpen(campaign);
	};

	const tableColumns: ColumnsType<IPromotionTableData> = useMemo(() => {
		const possibleActions = Object.values(EPromotionListActions).filter((actionKey) => CAMPAIGN_ACTIONS[actionKey]);

		const actions: ColumnType<IPromotionTableData> = {
			key: 'actions',
			title: tCommon('table.headers.actions'),
			width: getActionButtonsColumnWidth(possibleActions.length),
			fixed: 'right' as FixedType,
			render: (_, record) => {
				let recordCanLaunch = record?.actions?.can_launch;
				let recordCanPause = record?.actions?.can_pause;
				let recordCanResume = record?.actions?.can_resume;
				let recordCanStop = record?.actions?.can_stop;

				const actionsRender: Record<EPromotionListActions, IActionMenuItem> = {
					[EPromotionListActions.VIEW]: {
						type: 'button',
						title: tCommon('action_buttons.view'),
						icon: <EyeOutlined />,
						actionCb: () => onConsult(record),
					},
					[EPromotionListActions.RESUME]: {
						type: 'button',
						title: tCommon('action_buttons.resume'),
						disabled: !recordCanResume,
						icon: <PlayCircleOutlined />,
						actionCb: () => handleChangeCampaignStatus(EPromotionListActions.RESUME, record),
					},
					[EPromotionListActions.PUSH]: {
						type: 'button',
						title: tCommon('action_buttons.push'),
						disabled: !recordCanLaunch,
						icon: <RocketOutlined rotate={45} />,
						actionCb: () => handleChangeCampaignStatus(EPromotionListActions.PUSH, record),
					},
					[EPromotionListActions.LAUNCH]: {
						type: 'button',
						title: tCommon('action_buttons.launch'),
						disabled: !recordCanLaunch,
						icon: <RocketOutlined rotate={45} />,
						actionCb: () => handleChangeCampaignStatus(EPromotionListActions.LAUNCH, record),
					},
					[EPromotionListActions.PAUSE]: {
						type: 'button',
						title: tCommon('action_buttons.pause'),
						disabled: !recordCanPause,
						icon: <PauseCircleOutlined />,
						actionCb: () => handleChangeCampaignStatus(EPromotionListActions.PAUSE, record),
					},
					[EPromotionListActions.STOP]: {
						type: 'button',
						title: tCommon('action_buttons.stop'),
						disabled: !recordCanStop,
						icon: <StopOutlined />,
						actionCb: () => handleChangeCampaignStatus(EPromotionListActions.STOP, record),
					},
				};

				const actions = possibleActions.map((actionKey) => actionsRender[actionKey]);

				return <ActionsMenu actions={actions} />;
			},
		};

		const filteredColumns = defaultTableColumns.filter((column) => selectedColumns[column.key!]);

		return [...filteredColumns, actions];
		// eslint-disable-next-line
	}, [defaultTableColumns, selectedColumns]);
	return (
		<Space
			direction='vertical'
			size='large'
			className='w-100'
		>
			{/* Campaigns Recommendations */}
			<RecommendedSection
				key={forceRefreshData}
				refreshingData={refreshingData}
			/>

			<TableWrapper<IPromotionTableData, IPromotionListResponse>
				key='promotion-table-code'
				scrollX={'max-content'}
				pageTitle={
					<PageHeader
						title={tCampaigns('title')}
						subTitle={`[#${vendorId}] ${selectedVendor?.name}`}
						style={{ paddingBottom: 0 }}
					/>
				}
				searchPlaceholder={tCampaigns('table.placeholders.search')}
				columns={tableColumns}
				requestConfig={requestConfig}
				refetchData={forceRefreshData}
				transformDataToTableData={(dataElement: IPromotionListResponse) => ({
					...dataElement,
					key: dataElement.id,
				})}
				tableExtraActions={
					<ColumnFiltersSelect
						selectedColumns={selectedColumns}
						defaultTableColumns={defaultTableColumns}
						setSelectedColumns={setSelectedColumns}
					/>
				}
				headerExtraActions={
					<Button
						key='add_promotion_code_btn'
						type='primary'
						onClick={() => {
							navigator(
								generatePath(ABSOLUTE_ROUTES.CAMPAIGN_CREATE, {
									vendorId,
								})
							);
						}}
					>
						{tCampaigns('add_btn')}
					</Button>
				}
			/>

			<ViewCampaignDrawer
				open={openDrawer}
				campaignData={selectedCampaign!}
				handleOnClose={handleCampaignPreviewClose}
			/>
		</Space>
	);
};

export default CampaignsList;
