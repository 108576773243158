import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useMemo, useState } from 'react';
import { ICountry } from 'types/api';
import { ESupportedCountriesIsoTwoCodes, ISelectOption } from 'types/common';
import { useSettings } from './useSettings';

interface ICountryContextData {
	currentCountry?: ICountry;
	countriesList: ICountry[];
	selectedCountryCurrencyCode: ICountry['currency_code'];
	selectedCountryIsoTwoCode: ICountry['iso_two_code'];
	countryCodeOptionsList: ISelectOption[];
	isSaudiArabiaCountry: boolean;

	setCountriesList: Dispatch<SetStateAction<ICountry[]>>;
}

const CountryContext = createContext<ICountryContextData>({} as ICountryContextData);

const useCountry = () => {
	return useContext(CountryContext);
};

const CountryProvider = ({ children }: PropsWithChildren) => {
	const [countriesList, setCountriesList] = useState<ICountry[]>([]);

	// ! selectors
	const { selectedCountryId } = useSettings();

	// ! memos
	const currentCountry = useMemo<ICountry | undefined>(() => {
		const selectedCountry = countriesList.find((country) => country.id === selectedCountryId);

		return selectedCountry;
	}, [countriesList, selectedCountryId]);

	const selectedCountryCurrencyCode = useMemo<ICountry['currency_code']>(() => {
		return currentCountry?.currency_code || '';
	}, [currentCountry]);

	const selectedCountryIsoTwoCode = useMemo<ICountry['iso_two_code']>(() => {
		return currentCountry?.iso_two_code.toLowerCase() || '';
	}, [currentCountry]);

	const countryCodeOptionsList = useMemo<ISelectOption[]>(() => {
		if (!currentCountry) {
			return [];
		}

		return [
			{
				value: currentCountry.phone_code,
				label: `${currentCountry.iso_two_code} (${currentCountry.phone_code})`,
			},
		];
	}, [currentCountry]);

	const isSaudiArabiaCountry = useMemo(() => {
		return currentCountry?.iso_two_code === ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA;
	}, [currentCountry]);

	const countriesData: ICountryContextData = {
		countriesList,
		currentCountry,
		selectedCountryCurrencyCode,
		selectedCountryIsoTwoCode,
		countryCodeOptionsList,
		isSaudiArabiaCountry,

		setCountriesList,
	};

	return <CountryContext.Provider value={countriesData}>{children}</CountryContext.Provider>;
};

export { useCountry, CountryProvider };
