import { ITabItemConfig } from 'types/common';

export enum EVendorBranchTabsKeys {
	DETAILS = 'details',
	MENU = 'menu',
	WORK_SHIFT = 'work-shift',
	DELIVERY_AREA = 'delivery-area',
}

export interface IVendorInfoTabItem extends ITabItemConfig {
	key: EVendorBranchTabsKeys;
}
