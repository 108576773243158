import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import type { Ref } from 'react';
import { Nullable } from 'types/common';

interface IPolygonEventProps {
	onClick?: (e: google.maps.MapMouseEvent) => void;
	onDrag?: (e: google.maps.MapMouseEvent) => void;
	onDragStart?: (e: google.maps.MapMouseEvent) => void;
	onDragEnd?: (e: google.maps.MapMouseEvent) => void;
	onMouseOver?: (e: google.maps.MapMouseEvent) => void;
	onMouseOut?: (e: google.maps.MapMouseEvent) => void;
	onPolygonChange?: (path: google.maps.LatLngLiteral[]) => void;
}

interface IPolygonCustomProps {
	path?: google.maps.MVCArray<google.maps.LatLng> | (google.maps.LatLng | google.maps.LatLngLiteral)[];
}

export type TPolygonProps = google.maps.PolygonOptions & IPolygonEventProps & IPolygonCustomProps;

export type TPolygonRef = Ref<Nullable<google.maps.Polygon>>;

const usePolygon = (props: TPolygonProps) => {
	const {
		onClick,
		onDrag,
		onDragStart,
		onDragEnd,
		onMouseOver,
		onMouseOut,
		onPolygonChange,
		path,
		...polygonOptions
	} = props;

	const map = useMap();

	const callbacks = useRef<Record<string, (e: unknown) => void>>({});
	Object.assign(callbacks.current, {
		onClick,
		onDrag,
		onDragStart,
		onDragEnd,
		onMouseOver,
		onMouseOut,
		onPolygonChange,
	});

	const geometryLibrary = useMapsLibrary('geometry');

	// Only create the polygon once
	const polygonRef = useRef<google.maps.Polygon | null>(null);
	if (!polygonRef.current) {
		polygonRef.current = new google.maps.Polygon();
	}

	const polygon = polygonRef.current;

	// ! memos
	// Set polygon options whenever they change
	useMemo(() => {
		polygon.setOptions({
			...polygonOptions,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [polygonOptions]);

	// Set or update the polygon path
	useMemo(() => {
		if (!path || !geometryLibrary) return;
		polygon.setPath(path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path, geometryLibrary]);

	// ! effects
	// Set the polygon on the map and clean up when the component unmounts
	useEffect(() => {
		if (!map) {
			if (map === undefined) console.error('<Polygon> has to be inside a Map component.');
			return;
		}

		// Attach the polygon to the map
		polygon.setMap(map);

		return () => {
			polygon.setMap(null); // Clean up on unmount
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	const polygonPath = polygon.getPath(); // Get the path as MVCArray

	// Attach event listeners for polygon and path changes
	useEffect(() => {
		if (!polygon) return;

		const gme = google.maps.event;

		// Callback to update polygon path
		const updatePath = () => {
			if (callbacks.current.onPolygonChange) {
				const newPath = polygonPath.getArray().map((latLng: google.maps.LatLng) => ({
					lat: latLng.lat(),
					lng: latLng.lng(),
				}));

				callbacks.current.onPolygonChange(newPath);
			}
		};

		// Attach event listeners for path changes
		gme.addListener(polygonPath, 'set_at', updatePath); // Fires when a vertex is changed
		gme.addListener(polygonPath, 'insert_at', updatePath); // Fires when a vertex is added
		gme.addListener(polygonPath, 'remove_at', updatePath); // Fires when a vertex is removed

		// Attach regular polygon events
		[
			['click', 'onClick'],
			['drag', 'onDrag'],
			['dragstart', 'onDragStart'],
			['dragend', 'onDragEnd'],
			['mouseover', 'onMouseOver'],
			['mouseout', 'onMouseOut'],
		].forEach(([eventName, eventCallback]) => {
			gme.addListener(polygon, eventName, (e: google.maps.MapMouseEvent) => {
				const callback = callbacks.current[eventCallback];
				if (callback) callback(e);
			});
		});

		return () => {
			gme.clearInstanceListeners(polygon);
		};
	}, [polygon, polygonPath]);

	// ! return
	return polygon;
};

export const Polygon = forwardRef((props: TPolygonProps, ref: TPolygonRef) => {
	const polygon = usePolygon(props);

	useImperativeHandle(
		ref,
		() => polygon,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return null;
});
