import { FC, PropsWithChildren, createContext, useState } from 'react';
import { DEFAULT_HOURS_FILTERS } from 'configs/common';
import { THourRanges } from 'components/HoursFilterGroup';
import { DATE_SORT_BUTTONS_CONFIG, DISPATCHER_DEFAULT_ACTIVE_TAB } from 'pages/Dispatcher/configs';
import { EDispatcherTab } from 'pages/Dispatcher/types';

interface IDispatcherContextData {
	dateSort: string;
	hoursFilter: THourRanges;
	isDataRefreshEnable: boolean;
	activeStatusTab: EDispatcherTab;

	handleChangeDateSort: (sort: string) => void;
	handleTabChange: (newTab: EDispatcherTab) => void;
	handleDataRefreshChange: (value: boolean) => void;
	handleHoursFilterChange: (hours: THourRanges) => void;
}

export const DispatchContext = createContext<IDispatcherContextData>({} as IDispatcherContextData);

export const DispatcherProvider: FC<PropsWithChildren> = ({ children }) => {
	const [activeStatusTab, setActiveStatusTab] = useState<EDispatcherTab>(DISPATCHER_DEFAULT_ACTIVE_TAB);
	const [isDataRefreshEnable, setIsDataRefreshEnable] = useState<boolean>(true);
	const [dateSort, setDateSort] = useState<string>(DATE_SORT_BUTTONS_CONFIG[1].key);
	const [hoursFilter, setHoursFilter] = useState<THourRanges>(DEFAULT_HOURS_FILTERS[0]);

	// ! handlers
	const handleTabChange = (newTab: EDispatcherTab) => {
		setActiveStatusTab(newTab);
	};

	const handleDataRefreshChange = (value: boolean) => {
		setIsDataRefreshEnable(value);
	};

	const handleChangeDateSort = (sort: string) => {
		setDateSort(sort);
	};

	const handleHoursFilterChange = (hours: THourRanges) => {
		setHoursFilter(hours);
	};

	const data: IDispatcherContextData = {
		dateSort,
		hoursFilter,
		activeStatusTab,
		isDataRefreshEnable,

		handleTabChange,
		handleChangeDateSort,
		handleHoursFilterChange,
		handleDataRefreshChange,
	};

	return <DispatchContext.Provider value={data}>{children}</DispatchContext.Provider>;
};
