import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IProduct } from 'types/api';
import { Nullable } from 'types/common';
import { useCountry } from './useCountry';

interface IProductContextData {
	productId: number;
	categoryId?: string;
	subCategoryId?: string;
	currency: string;
	productData: Nullable<IProduct>;
	setProductData: Dispatch<SetStateAction<Nullable<IProduct>>>;
}

const ProductContext = createContext<IProductContextData>({} as IProductContextData);

const useProduct = () => {
	return useContext(ProductContext);
};

const ProductProvider = ({ productId: productIdArg, children }: PropsWithChildren<Partial<IProductContextData>>) => {
	const { productId = productIdArg, categoryId, subCategoryId } = useParams();
	const { selectedCountryCurrencyCode } = useCountry();

	const [productData, setProductData] = useState<Nullable<IProduct>>(null);

	if (!productId) return null;

	const productContextData: IProductContextData = {
		productId: +productId,
		categoryId,
		subCategoryId,
		currency: selectedCountryCurrencyCode,
		productData,
		setProductData,
	};

	return <ProductContext.Provider value={productContextData}>{children}</ProductContext.Provider>;
};

export default ProductProvider;
export { useProduct, ProductProvider };
