import { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ORDERS_API } from 'configs/api';
import { useHttpClient } from 'hooks';
import { IOrder } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';
import ErrorPage from 'pages/ErrorPage';

interface IOrderContextData {
	orderId: number;
	data: Nullable<IOrder>;
	isLoading: boolean;
	fetchData: TEmptyFunction;
}

const useOrderContext = () => {
	return useContext(OrderContext);
};

export default useOrderContext;

export const OrderContext = createContext<IOrderContextData>({} as IOrderContextData);

interface IOrderProviderProps {
	orderId?: number;
}

export const OrderProvider: FC<PropsWithChildren<IOrderProviderProps>> = ({ children, orderId: orderIdArg }) => {
	const { orderId: parameterOrderId } = useParams();

	const parsedParameterOrderId: number | undefined = parameterOrderId ? Number.parseInt(parameterOrderId) : undefined;

	// ! http clients
	const fetchOrderClient = useHttpClient<IOrder>();

	// ! memos
	const orderId: undefined | number = useMemo(
		() => orderIdArg ?? parsedParameterOrderId,
		[orderIdArg, parsedParameterOrderId]
	);

	// ! handlers
	const fetchData = (orderId: number) => {
		if (fetchOrderClient.isLoading) return;

		fetchOrderClient.request({
			requestConfig: ORDERS_API.get(orderId),
		});
	};

	// ! effects
	useEffect(() => {
		if (orderId) fetchData(orderId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId]);

	// ! render
	if (fetchOrderClient.error) {
		const error = fetchOrderClient.error;
		return (
			<ErrorPage
				status={error.status}
				title={error.status}
				subTitle={error.data?.message}
			/>
		);
	}

	if (!orderId) return null;

	const providerData: IOrderContextData = {
		orderId,
		isLoading: fetchOrderClient.isLoading,
		data: fetchOrderClient.response,
		fetchData: () => fetchData(orderId),
	};

	return <OrderContext.Provider value={providerData}>{children} </OrderContext.Provider>;
};
