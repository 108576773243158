import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useMemo, useState } from 'react';
import { APP_LANGUAGE_STORAGE_KEY, SUPPORTED_LANGUAGES, TIMEZONE_STORAGE_KEY } from 'configs/common';
import { UTC_TIME_ZONE } from 'hooks';
import { IStorageUtils } from 'utils/storage';
import { ICountry } from 'types/api';
import { ESupportedLanguages } from 'types/common';

interface ISettingsContextData {
	isRTL: boolean;
	selectedTimezone: string;
	language: ESupportedLanguages;
	selectedCountryId?: ICountry['id'];

	setSelectedCountryId: Dispatch<SetStateAction<ICountry['id'] | undefined>>;
	handleLanguageChange: (newLanguage: ESupportedLanguages) => void;
	handleTimezoneChange: (newSelectedTimezone: string, storage: IStorageUtils) => void;
}

export const SettingsContext = createContext<ISettingsContextData>({} as ISettingsContextData);

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
	// ! states
	const [selectedCountryId, setSelectedCountryId] = useState<ICountry['id']>();
	const [selectedTimezone, setSelectedTimezone] = useState<string>(UTC_TIME_ZONE);

	const [language, setLanguage] = useState<ESupportedLanguages>(
		(localStorage.getItem('i18nextLng') as ESupportedLanguages) ?? ESupportedLanguages.ENGLISH
	);

	// ! memos
	const isRTL = useMemo<boolean>(() => {
		const languageConfig = SUPPORTED_LANGUAGES[language];
		return languageConfig?.defaultDirection === 'rtl';
	}, [language]);

	// ! handlers
	const handleTimezoneChange = (newSelectedTimezone: string, storage: IStorageUtils) => {
		setSelectedTimezone(newSelectedTimezone);
		storage.set(TIMEZONE_STORAGE_KEY, newSelectedTimezone);
	};

	const handleLanguageChange = (newLanguage: ESupportedLanguages) => {
		setLanguage(newLanguage);
		localStorage.setItem(APP_LANGUAGE_STORAGE_KEY, newLanguage);
	};

	// ! render
	const data: ISettingsContextData = {
		isRTL,
		language,
		selectedTimezone,
		selectedCountryId,

		handleTimezoneChange,
		setSelectedCountryId,
		handleLanguageChange,
	};

	return <SettingsContext.Provider value={data}>{children}</SettingsContext.Provider>;
};
