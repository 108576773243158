import { ChangeEvent, FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { ID_COLUMN_WIDTH } from 'configs/common';
import { useAuth } from 'hooks';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { EVendorUserType } from 'types/api';
import { EInvoiceListType, TInvoiceTable } from './types';
import DisplayNameAndNameAr from 'components/DisplayNameAndNameAr';
import TranslatedLabel from 'components/TranslatedLabel';
import { Input, Tooltip } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnsType } from 'antd/es/table';

const INVOICE_TABLE_DEFAULT_COLUMNS: ColumnsType<TInvoiceTable> = [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='invoices'
				i18nKey='shared.entity.id'
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	{
		key: 'name',
		title: (
			<TranslatedLabel
				nameSpace='invoices'
				i18nKey='shared.entity.name'
			/>
		),
		dataIndex: 'name',
		sorter: true,
	},
	{
		key: 'created_at',
		title: (
			<TranslatedLabel
				nameSpace='invoices'
				i18nKey='shared.entity.created_at'
			/>
		),
		render: (_, { created_at }) => <LocalTimeDate date={created_at} />,
		sorter: true,
	},
];

export const INVOICE_LIST_CONFIG: Record<
	EInvoiceListType,
	{
		pageTitle?: string | JSX.Element;
		defaultColumns: ColumnsType<TInvoiceTable>;
		defaultControlSizes?: SizeType;
	}
> = {
	[EInvoiceListType.GLOBAL_PAGE]: {
		pageTitle: (
			<TranslatedLabel
				nameSpace='invoices'
				i18nKey='title'
			/>
		),
		defaultColumns: [
			...INVOICE_TABLE_DEFAULT_COLUMNS,
			{
				key: 'vendor_name',
				title: (
					<TranslatedLabel
						nameSpace='invoices'
						i18nKey='shared.entity.vendor'
					/>
				),
				render: (_, record) => {
					const LinkWrapper: FC<PropsWithChildren> = ({ children }) => {
						const { user } = useAuth();

						if (user?.type === EVendorUserType.VENDOR_MANAGER) {
							return <Link to={record.vendor_path}>{children}</Link>;
						}

						return <>{children}</>;
					};

					return (
						<LinkWrapper>
							<Tooltip title={record?.vendor_id ? `#${record?.vendor_id}` : undefined}>
								<DisplayNameAndNameAr
									name={record.vendor_name}
									nameAr={record.vendor_name_ar}
								/>
							</Tooltip>
						</LinkWrapper>
					);
				},
				filterSearch: true,
				filterDropdown: ({ confirm, setSelectedKeys }) => {
					const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
						setSelectedKeys(target.value?.trim().length ? [target.value] : []);
						confirm({ closeDropdown: false });
					};
					const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

					return (
						<Input
							autoFocus
							allowClear
							onChange={debouncedHandleSearch}
						/>
					);
				},
			},
		],
		defaultControlSizes: 'large',
	},
	[EInvoiceListType.VENDOR_PAGE]: {
		pageTitle: undefined,
		defaultColumns: [...INVOICE_TABLE_DEFAULT_COLUMNS],
		defaultControlSizes: 'middle',
	},
};
