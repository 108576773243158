import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TIMEZONE_STORAGE_KEY } from 'configs/common';
import { UTC_TIME_ZONE, useCountry, useGlobalConfigs, useSettings } from 'hooks';
import { Clock } from 'components/Clock';
import styles from './TimezoneSelector.module.css';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ClockCircleOutlined } from '@ant-design/icons';

export const TimezoneSelector: FC = () => {
	const { storage } = useGlobalConfigs();
	const { t: tCommon } = useTranslation('common');

	const { currentCountry } = useCountry();
	const { selectedTimezone, handleTimezoneChange } = useSettings();

	// ! useMemo
	const timeZoneOptions: DefaultOptionType[] = useMemo(() => {
		const resultList: DefaultOptionType[] = [];
		// Current timezone
		resultList.push({
			label: (
				<Clock
					timeZoneName={tCommon('header.time_zones.current_timezone')}
					timezone={dayjs.tz.guess()}
				/>
			),
			value: dayjs.tz.guess(),
		});
		// UTC
		resultList.push({
			label: <Clock timeZoneName={tCommon('header.time_zones.utc_timezone')} />,
			value: UTC_TIME_ZONE,
		});
		// Country timezones
		const countryTimeZoneList =
			currentCountry?.timezones.map(
				(tz): DefaultOptionType => ({ value: tz.name, label: <Clock timezone={tz.name} /> })
			) ?? [];

		resultList.push(...countryTimeZoneList);

		return resultList;
	}, [currentCountry?.timezones, tCommon]);

	// ! handlers
	// ? timeZone management
	const handleTimeZoneSelect = (newSelectedTimezone: string) => {
		handleTimezoneChange(newSelectedTimezone, storage);
	};

	// ? init time zone based on local storage
	useEffect(() => {
		const timeZone = storage.get(TIMEZONE_STORAGE_KEY);

		let newValidatedTimeZone = UTC_TIME_ZONE;

		try {
			if (!timeZone) throw new Error();

			// test if timeZone is valid. It will return error if not
			dayjs().tz(timeZone);

			newValidatedTimeZone = timeZone;
		} catch (error) {
			newValidatedTimeZone = dayjs.tz.guess();
		}

		handleTimezoneChange(newValidatedTimeZone, storage);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<Select<string>
			suffixIcon={<ClockCircleOutlined />}
			showSearch={false}
			size='small'
			placeholder={tCommon('header.time_zone_placeholder')}
			options={timeZoneOptions}
			value={selectedTimezone}
			className={styles.time_zone_select}
			onChange={handleTimeZoneSelect}
		/>
	);
};
