import { Map, MapProps, useMap } from '@vis.gl/react-google-maps';
import { FC, useEffect } from 'react';
import { IVisCommonMapProps } from './types';

const mapStylesOptions: MapProps = {
	clickableIcons: false,
	minZoom: 8, //zoom out
	styles: [
		// TODO : move this out since new Google MAps JavaScript API warns about styles being controlled on the cloud console
		{
			featureType: 'poi',
			elementType: 'labels',
			stylers: [{ visibility: 'off' }],
		},
	],
};

const OnMapLoad: FC<Pick<IVisCommonMapProps, 'onLoad'>> = ({ onLoad }) => {
	const map = useMap();

	useEffect(() => {
		if (map && onLoad) {
			onLoad(map);
		}
	});

	return <></>;
};

const VisCommonMap: FC<IVisCommonMapProps> = ({ mapId, children, defaultCenter, defaultZoom, onLoad, ...mapProps }) => {
	// ! render
	return (
		<Map
			mapId={mapId}
			defaultZoom={defaultZoom}
			defaultCenter={defaultCenter}
			{...mapProps}
			{...mapStylesOptions}
		>
			<OnMapLoad />

			{children}
		</Map>
	);
};

export default VisCommonMap;
