import { Marker } from '@vis.gl/react-google-maps';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER, DEFAULT_MAP_CENTER } from 'configs/common';
import { OPERATIONS_MAP_CONFIG } from './configs';
import { useBranch, useHttpClient } from 'hooks';
import { useBranchDeliveryAreaListController } from 'hooks/useAreaListController';
import { IArea } from 'types/api';
import { IListResponse } from 'types/common';
import { AreaPolygon } from 'components/Map/AreaPolygon';
import Spinner from 'components/Spinner';
import VisCommonMap from 'components/VisCommonMap';
import { AreaInfoWindow } from './AreaInfoWindow';
import { Typography as T } from 'antd';
import locationPinLogo from 'assets/images/location-pin.svg';

const DeliveryAreas: FC = () => {
	const { data: branchData } = useBranch();

	const { areas, areaMap, selectedAreas, areaIdInfoWindow, setAreaIdInfoWindow, hoveredAreaId, setHoveredAreaId } =
		useBranchDeliveryAreaListController();

	const { t: tVendorBranchDeliveryArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area',
	});

	// ! httpClients
	const getHttpClient = useHttpClient<IListResponse<IArea>>();

	// ! state
	const [branchLocation, setBranchLocation] = useState<google.maps.LatLngLiteral>();

	// ! effects

	useEffect(() => {
		if (!branchData) return;

		const { location } = branchData;

		setBranchLocation({ lat: +location.lat, lng: +location.lng });
	}, [branchData]);

	return (
		<div className='h-100 flex flex-column'>
			{/* Header */}
			<div style={{ paddingBottom: 8 }}>
				{getHttpClient.isLoading && <Spinner />}
				<T.Text strong>
					{`${tVendorBranchDeliveryArea('selected_areas')}: ${
						getHttpClient.isLoading ? DEFAULT_EMPTY_VALUE_PLACEHOLDER : Object.values(selectedAreas).length
					}`}
				</T.Text>
			</div>

			{/* MAP */}
			<div className='flex-fill'>
				<VisCommonMap
					mapId='delivery-areas-map'
					defaultCenter={branchLocation || DEFAULT_MAP_CENTER}
					defaultZoom={11}
					style={{ ...OPERATIONS_MAP_CONFIG, height: '100%' }}
				>
					{branchLocation && (
						<Marker
							title={tVendorBranchDeliveryArea('branch_location')}
							icon={locationPinLogo}
							position={branchLocation}
						/>
					)}

					{areas.map((area) => (
						<div key={area.id}>
							<AreaPolygon
								area={area}
								clickable
								isHovered={hoveredAreaId === area.id}
								isSelected={selectedAreas[area.id]}
								isVisible
								onClick={() => setAreaIdInfoWindow(area.id)}
								onMouseOver={() => setHoveredAreaId(area.id)}
							/>
						</div>
					))}

					{areaIdInfoWindow && (
						<AreaInfoWindow
							area={areaMap.current[areaIdInfoWindow]}
							onCloseClick={() => setAreaIdInfoWindow(null)}
						/>
					)}
				</VisCommonMap>
			</div>
		</div>
	);
};

export default DeliveryAreas;
