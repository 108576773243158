import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PROMOTION_TARGET_OPTIONS } from './configs';
import { useAuth, useLocaleTimeUtils } from 'hooks';
import { useCampaignCreateController } from '../hooks/useCampaignCreateController';
import { DATE_TIME_INTL_FORMAT } from 'utils/date';
import { ECampaignFormFields, EPromotionDiscountType, EPromotionUsageType } from 'types/api/promotions';
import { FormSectionLabels } from 'components/FormSectionLabels';
import Spinner from 'components/Spinner';
import DiscountValueInput from '../SharedComponents/DiscountValueInput';
import { FormCardRadioGroup } from '../SharedComponents/FormCardRadioGroup';
import TimeRangeSlider from '../SharedComponents/TimeRangeSlider';
import styles from './CreateCampaign.module.css';
import CreateCampaignFooter from './CreateCampaignFooter';
import { Col, DatePicker, Flex, Form, Input, Row, Space, Switch, Tag } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { LikeOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';

const CreateCampaign: FC = () => {
	const navigator = useNavigate();
	const { t: tCampaigns } = useTranslation('campaigns');
	const { datePickerDisablePreviousDatesFromNow } = useLocaleTimeUtils();

	const controller = useCampaignCreateController();

	const { form, formData, isFreeDelivery, handleTargetChange, handleIncentiveTypeChange, isFetching } = controller;

	const { user } = useAuth();
	const { vendorId } = useParams();

	const selectedVendor = user?.vendors.find((vendor) => vendor.id === +vendorId!);

	return (
		<>
			<PageHeader
				title={tCampaigns('create.title')}
				subTitle={`[#${selectedVendor?.id}] ${selectedVendor?.name}`}
				onBack={() => navigator(-1)}
			/>
			<Flex
				vertical
				gap={16}
				className={styles.main_container}
			>
				{isFetching && <Spinner />}

				{!isFetching && (
					<>
						<Form
							form={form}
							id='createCampaignForm'
							layout='vertical'
						>
							<Form.Item
								name={[ECampaignFormFields.MULTI_VENDOR, 0, ECampaignFormFields.MULTI_VENDOR_VENDOR_ID]}
								hidden
							>
								<Input />
							</Form.Item>

							<Row gutter={[16, 16]}>
								<Col
									span={24}
									className={styles.column}
								>
									<FormCardRadioGroup
										name={ECampaignFormFields.TARGET}
										label={tCampaigns(`create.form.${ECampaignFormFields.TARGET}.label`)}
										extra={tCampaigns(`create.form.${ECampaignFormFields.TARGET}.description`)}
										rules={[{ required: true }]}
										value={formData.target}
										options={PROMOTION_TARGET_OPTIONS}
										onChange={handleTargetChange}
										disabled={false}
									/>
								</Col>

								<Col span={24}>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<FormSectionLabels
												label={tCampaigns(`create.form.${ECampaignFormFields.TITLE}.label`)}
												extra={tCampaigns(
													`create.form.${ECampaignFormFields.TITLE}.description`
												)}
											/>
										</Col>
										<Col span={12}>
											<FormItem
												name={ECampaignFormFields.TITLE}
												label={tCampaigns(
													`create.form.title.inputs.${ECampaignFormFields.TITLE}.label`
												)}
												rules={[{ required: true, whitespace: true }]}
											>
												<Input
													size='large'
													placeholder={tCampaigns(
														`create.form.title.inputs.${ECampaignFormFields.TITLE}.placeholder`
													)}
												/>
											</FormItem>
										</Col>
										<Col span={12}>
											<FormItem
												name={ECampaignFormFields.TITLE_AR}
												label={tCampaigns(
													`create.form.title.inputs.${ECampaignFormFields.TITLE_AR}.label`
												)}
												rules={[{ required: true, whitespace: true }]}
											>
												<Input
													size='large'
													placeholder={tCampaigns(
														`create.form.title.inputs.${ECampaignFormFields.TITLE_AR}.placeholder`
													)}
												/>
											</FormItem>
										</Col>
									</Row>
								</Col>
								{formData.usage_type === EPromotionUsageType.MANUAL && (
									<Col span={24}>
										<Row gutter={[16, 16]}>
											<Col span={24}>
												<FormSectionLabels
													label={tCampaigns(
														`create.form.${ECampaignFormFields.DESCRIPTION}.label`
													)}
													extra={tCampaigns(
														`create.form.${ECampaignFormFields.DESCRIPTION}.description`
													)}
												/>
											</Col>
											<Col span={12}>
												<FormItem
													name={ECampaignFormFields.DESCRIPTION}
													label={tCampaigns(
														`create.form.description.inputs.${ECampaignFormFields.DESCRIPTION}.label`
													)}
													rules={[{ required: true, whitespace: true }]}
												>
													<Input
														size='large'
														placeholder={tCampaigns(
															`create.form.description.inputs.${ECampaignFormFields.DESCRIPTION}.placeholder`
														)}
													/>
												</FormItem>
											</Col>
											<Col span={12}>
												<FormItem
													name={ECampaignFormFields.DESCRIPTION_AR}
													label={tCampaigns(
														`create.form.description.inputs.${ECampaignFormFields.DESCRIPTION_AR}.label`
													)}
													rules={[{ required: true, whitespace: true }]}
												>
													<Input
														size='large'
														placeholder={tCampaigns(
															`create.form.description.inputs.${ECampaignFormFields.DESCRIPTION_AR}.placeholder`
														)}
													/>
												</FormItem>
											</Col>
										</Row>
									</Col>
								)}
								<Col
									span={24}
									className={styles.column}
								>
									<FormCardRadioGroup
										name={ECampaignFormFields.DISCOUNT_TYPE}
										label={tCampaigns(`create.form.${ECampaignFormFields.DISCOUNT_TYPE}.label`)}
										value={formData.discount_type}
										options={[
											{
												value: EPromotionDiscountType.PERCENTAGE,
												label: tCampaigns(
													`create.form.discount_type.${EPromotionDiscountType.PERCENTAGE}.label`
												),
												description: tCampaigns(
													`create.form.discount_type.${EPromotionDiscountType.PERCENTAGE}.description`
												),
											},
											{
												value: EPromotionDiscountType.AMOUNT,
												label: tCampaigns(
													`create.form.discount_type.${EPromotionDiscountType.AMOUNT}.label`
												),
												description: tCampaigns(
													`create.form.discount_type.${EPromotionDiscountType.AMOUNT}.description`
												),
											},
											{
												value: EPromotionDiscountType.FREE_DELIVERY,
												label: tCampaigns(
													`create.form.discount_type.${EPromotionDiscountType.FREE_DELIVERY}.label`
												),
												description: tCampaigns(
													`create.form.discount_type.${EPromotionDiscountType.FREE_DELIVERY}.description`
												),
											},
										]}
										onChange={handleIncentiveTypeChange}
										showSelectedIcon
									/>
								</Col>
								<Col
									span={24}
									className={styles.column}
								>
									<Row
										className='w-100'
										gutter={[16, 16]}
									>
										<Col>
											<FormSectionLabels
												label={tCampaigns(
													`create.form.${ECampaignFormFields.USAGE_TYPE}.label`
												)}
												extra={tCampaigns(
													`create.form.${ECampaignFormFields.USAGE_TYPE}.description`
												)}
											/>
										</Col>
										<Col span={24}>
											<DiscountValueInput promotionCreateController={controller} />
										</Col>
									</Row>
								</Col>

								<Col
									span={24}
									className={styles.column}
								>
									<Row gutter={[24, 24]}>
										<Col span={24}>
											<FormSectionLabels
												label={tCampaigns('create.form.time_range.label')}
												extra={tCampaigns('create.form.time_range.description')}
											/>
										</Col>
										<Col span={16}>
											<FormItem
												name={ECampaignFormFields.END_DATE}
												hidden
											>
												<Input />
											</FormItem>
											<FormItem
												name={ECampaignFormFields.START_DATE}
												label={tCampaigns(
													`create.form.${ECampaignFormFields.START_DATE}.label`
												)}
												extra={tCampaigns(
													`create.form.${ECampaignFormFields.START_DATE}.description`
												)}
												rules={[{ required: true }]}
												wrapperCol={{
													style: { display: 'flex', flexDirection: 'column-reverse' },
												}}
											>
												<DatePicker
													className='w-100'
													showTime
													showSecond={false}
													format={DATE_TIME_INTL_FORMAT}
													disabledDate={datePickerDisablePreviousDatesFromNow}
												/>
											</FormItem>
										</Col>
										<Col span={16}>
											<TimeRangeSlider
												disabled={false}
												form={controller.form}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={24}>
									<Row gutter={[24, 24]}>
										{/* <> FREE DELIVERY </> */}
										<Col>
											<FormSectionLabels
												label={tCampaigns('create.form.free_delivery.label')}
												extra={tCampaigns('create.form.free_delivery.description')}
											/>
										</Col>
										<Col span={24}>
											<Space size='small'>
												<FormItem
													name={ECampaignFormFields.FREE_DELIVERY}
													valuePropName='checked'
													noStyle
												>
													<Switch disabled={isFreeDelivery} />
												</FormItem>
												{tCampaigns('create.form.free_delivery.switch_label')}
											</Space>
										</Col>

										<Col span={24}>
											<Tag
												color='processing'
												className={styles.free_delivery_tag}
												icon={<LikeOutlined style={{ fontSize: 24 }} />}
											>
												<Space direction='vertical'>
													<span
														className={`${styles.free_delivery_tag_title} ${styles.free_delivery_tag_text}`}
													>
														{tCampaigns('create.form.free_delivery.alert.title')}
													</span>
													<span className={styles.free_delivery_tag_text}>
														{formData[ECampaignFormFields.FREE_DELIVERY] ? (
															<span>
																{tCampaigns(
																	'create.form.free_delivery.alert.confirm.usage'
																)}
															</span>
														) : (
															<>
																{tCampaigns(
																	'create.form.free_delivery.alert.support_text.before_bold'
																)}
																<strong>
																	{tCampaigns(
																		'create.form.free_delivery.alert.support_text.bold_text'
																	)}
																</strong>
																{tCampaigns(
																	'create.form.free_delivery.alert.support_text.after_bold'
																)}
															</>
														)}
													</span>
												</Space>
											</Tag>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form>

						<CreateCampaignFooter controller={controller} />
					</>
				)}
			</Flex>
		</>
	);
};

export default CreateCampaign;
