import React, { FC, Suspense, memo, useEffect, useRef, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import i18next from 'i18next';
import { ORDERS_API } from 'configs/api';
import { useHttpClient } from 'hooks';
import { IOrder } from 'types/api';
import { ESupportedLanguages, Nullable } from 'types/common';
import { IOrderDataPrintProps, IOrderPrintProps, TUseReactToPrintHookReturn } from './types';
import OrderPrintReceiptLayout from './OrderPrintReceiptLayout';
import { PrintLanguageChoiceModal } from './PrintLanguageChoiceModal';
import { ConfigProvider } from 'antd';
import { DirectionType } from 'antd/lib/config-provider';

const configs: Record<ESupportedLanguages, { dir: DirectionType }> = {
	ar: { dir: 'rtl' },
	en: { dir: 'ltr' },
};

const PrintComponentConfigs = React.forwardRef<any, IOrderDataPrintProps>(({ order, language }, ref) => {
	const i18nClone = i18next.cloneInstance();
	const languageConfig = configs[(language as ESupportedLanguages) ?? ESupportedLanguages.ENGLISH];
	const direction = languageConfig.dir;

	useEffect(() => {
		i18nClone.init();
		i18nClone.changeLanguage(language?.toLowerCase());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	return (
		<div className='print_preview'>
			<I18nextProvider i18n={i18nClone}>
				<ConfigProvider direction={direction}>
					<Suspense>
						<OrderPrintReceiptLayout
							order={order}
							ref={ref}
							direction={direction!}
						/>
					</Suspense>
				</ConfigProvider>
			</I18nextProvider>
		</div>
	);
});

const OrderPrint: FC<IOrderPrintProps> = memo(({ orderId }) => {
	const [printOrderData, setPrintOrderData] = useState<Nullable<IOrder>>(null);
	const printComponentRef = useRef();

	// ! http client
	const fetchOrderHttpClient = useHttpClient<IOrder>();

	const [printLanguage, setPrintLanguage] = useState<ESupportedLanguages>(ESupportedLanguages.ENGLISH);

	// ! fetch information
	const fetchOrderData = (orderId: number, language: ESupportedLanguages) =>
		fetchOrderHttpClient.request({
			requestConfig: ORDERS_API.get(orderId, language),
			successCallback: (response) => {
				setPrintOrderData(response);
			},
		});

	// ! handlers
	const handlePrint = useReactToPrint({
		content: () => printComponentRef?.current ?? null,
		documentTitle: `order-receipt-${printOrderData?.info.code}-${printLanguage}`,
	});

	const onPrintButton = (handlePrint: TUseReactToPrintHookReturn) => async (language: ESupportedLanguages) => {
		setPrintLanguage(language);

		if (!orderId) return;

		fetchOrderData(orderId, language);

		/*
		 * ! HAMMER TO REVIEW: ▬▬█ @tlsltaken ▬▬█
		 */
		setTimeout(() => {
			handlePrint();
		}, 500);
	};

	return (
		<>
			<PrintLanguageChoiceModal onPrint={onPrintButton(handlePrint)} />

			{printOrderData && (
				<div style={{ display: 'none' }}>
					{/* Component for debug usage */}
					{/* <OrderPrintDebuggerHelper
						order={printOrderData!}
						layout={LayoutComponent}
					/> */}

					<PrintComponentConfigs
						language={printLanguage}
						order={printOrderData}
						ref={printComponentRef}
					/>
				</div>
			)}
		</>
	);
});

export default OrderPrint;
