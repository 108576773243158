import { useCallback } from 'react';
import { useSettings } from 'hooks/useSettings';
import { DATE_TIME_INTL_FORMAT, transformToBEAcceptanceFormat } from 'utils/date';
import { THourRanges } from 'components/HoursFilterGroup';
import { UTC_TIME_ZONE } from './config';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export interface IUseLocaleTimeUtilsProps {
	selectedTimezone: string;
	parseDate: typeof dayjs;
	getCurrentTimeZoneDate: (date: string | Date | dayjs.Dayjs) => dayjs.Dayjs;
	formatDate: (date: string | Date | dayjs.Dayjs, showTimezone?: boolean, format?: string) => string;

	getTodayParamToFilter: () => string;
	getStartDateParamToFilter: (hoursFilter: number, milliSecondsFilter?: number) => string;
	getHourFilter: (hoursFilter?: THourRanges) => string | null;
	datePickerDisablePreviousDatesFromNow: (current: dayjs.Dayjs) => boolean;
	getNowInTimeZone: () => dayjs.Dayjs;
}

export const useLocaleTimeUtils = (): IUseLocaleTimeUtilsProps => {
	const { selectedTimezone } = useSettings();

	const getCurrentTimeZoneDate = useCallback(
		(date: string | Date | dayjs.Dayjs) => {
			let dateProcess = dayjs(date).utc();

			if (selectedTimezone !== UTC_TIME_ZONE) {
				dateProcess = dateProcess.tz(selectedTimezone);
			}

			return dateProcess;
		},
		[selectedTimezone]
	);

	const formatDate: IUseLocaleTimeUtilsProps['formatDate'] = useCallback(
		(date, showTimezone, format = DATE_TIME_INTL_FORMAT) => {
			const processDate = getCurrentTimeZoneDate(date);

			const tzDate = processDate.format(format);

			// build string
			if (showTimezone) {
				return `${tzDate} (${selectedTimezone})`;
			}
			return tzDate;
		},
		[getCurrentTimeZoneDate, selectedTimezone]
	);

	const getNowInTimeZone = () => dayjs().tz(selectedTimezone, true);

	const getTodayParamToFilter = () => transformToBEAcceptanceFormat(getNowInTimeZone().startOf('day'));

	const getStartDateParamToFilter = (hoursFilter: number, milliSecondsFilter: number = 0): string =>
		transformToBEAcceptanceFormat(
			dayjs().subtract(hoursFilter, 'hour').subtract(milliSecondsFilter, 'milliseconds')
		);

	const getHourFilter = (hoursFilter?: THourRanges) => {
		if (hoursFilter) {
			switch (hoursFilter) {
				case 'today': {
					return getTodayParamToFilter();
				}
				default: {
					return getStartDateParamToFilter(+hoursFilter);
				}
			}
		}

		return null;
	};

	const datePickerDisablePreviousDatesFromNow = (current: dayjs.Dayjs) =>
		!!current && current < getNowInTimeZone().startOf('day');

	// ! return
	const api: IUseLocaleTimeUtilsProps = {
		selectedTimezone,
		parseDate: dayjs,
		getCurrentTimeZoneDate,
		formatDate,

		// ! Others
		getTodayParamToFilter,
		getStartDateParamToFilter,
		getHourFilter,
		datePickerDisablePreviousDatesFromNow,
		getNowInTimeZone,
	};

	return api;
};
