import axios from 'axios';
import { AxiosInstance } from 'axios';
import qs from 'qs';
import { AUTH_API, AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY, REFRESH_TOKEN_URL } from 'configs/api';
import { APP_LANGUAGE_STORAGE_KEY } from 'configs/common';
import { isNumber } from 'utils/validationUtils/isNumber';
import { EHttpStatus, ICountry } from 'types/api';
import { ESupportedLanguages } from 'types/common';
import { IStorageUtils, getAuthTokenFromLocalStorage, getRefreshTokenFromLocalStorage } from './storage';
import { AppError } from 'exceptions/AppError';

export type THttpFunction = AxiosInstance;

// Create axios instance
export const http = (
	appConfig_api_url: string,
	storageUtils: IStorageUtils,
	countryId?: ICountry['id']
): AxiosInstance => {
	const axiosInstance: AxiosInstance = axios.create({
		baseURL: appConfig_api_url,
		headers: {
			'Content-Type': 'application/json',
		},
		paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
	});

	// Set the interceptors
	axiosInstance.interceptors.request.use(
		(config) => {
			// * token and language
			const token = getAuthTokenFromLocalStorage(storageUtils);

			if (token?.length) {
				const accept_language =
					config?.headers?.accept_language ??
					localStorage.getItem(APP_LANGUAGE_STORAGE_KEY) ??
					ESupportedLanguages.ENGLISH;

				config.headers = {
					...config.headers,
					Authorization: `Bearer ${token}`,
					'accept-language': accept_language,
					// 'ngrok-skip-browser-warning': 'skip', // NGROK Local Testing
				};
			}

			// * country
			if (!config.params?.country_id && isNumber(countryId)) {
				config.params = { ...config.params, country_id: countryId };
			}

			// * return config
			return config;
		},
		(error) => Promise.reject(error)
	);

	// ! UNAUTHORIZED INTERCEPTOR
	axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalConfig = error.config;

			if (originalConfig?.url !== REFRESH_TOKEN_URL) {
				// ACCESS TOKEN WAS EXPIRED
				if (error.response?.status === EHttpStatus.UNAUTHORIZED && !originalConfig._retry) {
					originalConfig._retry = true;
					const refreshToken = getRefreshTokenFromLocalStorage(storageUtils);
					if (refreshToken && refreshToken?.length > 0) {
						try {
							const { data } = await axiosInstance(AUTH_API.refreshToken(refreshToken));

							storageUtils.set(AUTH_TOKEN_KEY, data.access_token);
							storageUtils.set(REFRESH_TOKEN_KEY, data.refresh_token);
							return axiosInstance(originalConfig);
						} catch (_error) {
							// REFRESH TOKEN FAILED
							return Promise.reject(
								new AppError(
									'Your session has expired. Please sign in again.',
									EHttpStatus.UNAUTHORIZED
								)
							);
						}
					}
				}
			}
			return Promise.reject(error.response ?? error);
		}
	);

	return axiosInstance;
};
