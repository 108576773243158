import { InfoWindow } from '@vis.gl/react-google-maps';
import { FC } from 'react';
import { IArea } from 'types/api';
import { TEmptyFunction } from 'types/common';
import DisplayNameAndNameAr from 'components/DisplayNameAndNameAr';
import { getCenter } from 'components/Map/helper';
import { Typography } from 'antd';

interface IAreaInfoWindowProps {
	area: IArea;
	onCloseClick: TEmptyFunction;
}

export const AreaInfoWindow: FC<IAreaInfoWindowProps> = ({ area, onCloseClick }) => {
	// ! memos
	const getPolygonCentroid = getCenter(area.polygon?.coordinates ?? []);

	// ! render
	return (
		<InfoWindow
			position={getPolygonCentroid}
			onCloseClick={onCloseClick}
		>
			<div style={{ minWidth: 100, maxWidth: 220 }}>
				<div style={{ position: 'absolute', top: 14 }}>
					{/*  WORK around for title placement of InfoWindow */}

					<Typography.Text strong>{`#${area.id}`}</Typography.Text>
				</div>
				<DisplayNameAndNameAr
					name={area.name}
					nameAr={area.name_ar}
				/>
			</div>
		</InfoWindow>
	);
};
