import { FC, PropsWithChildren, createContext, useEffect } from 'react';
import { REFRESH_DATA_TTL } from 'configs/common';
import { useDispatcher } from 'hooks';
import { IWithRefreshTimer, useRefreshTimer } from 'hooks/useRefreshTimer';

export const GlobalRefreshTimerContext = createContext<IWithRefreshTimer>({} as IWithRefreshTimer);

export const GlobalRefreshTimerProvider: FC<PropsWithChildren> = ({ children }) => {
	const refreshTimer = useRefreshTimer(REFRESH_DATA_TTL);

	const { isDataRefreshEnable } = useDispatcher();

	useEffect(() => {
		refreshTimer.toggleRefreshEnable(isDataRefreshEnable);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDataRefreshEnable, refreshTimer.toggleRefreshEnable]);

	return <GlobalRefreshTimerContext.Provider value={refreshTimer}>{children}</GlobalRefreshTimerContext.Provider>;
};
